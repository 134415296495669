<template>
    <admin-user-component/>
</template>

<script>
    import AdminUserComponent from "@/components/admin/AdminUsersComponent";
    
    export default {
        name: "AdminUser",
        title: "Gestión de Usuarios | Equity Baja",
        components: {
            AdminUserComponent
        }
    }
</script>

<style scoped>

</style>