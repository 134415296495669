<template>
    <v-container fluid class="fill-height text-center">
        <v-dialog width="250px" v-model="qrDialog" persistent>
            <v-card>
                <v-card-title class="text-h6 headline">
                    C&oacute;digo QR
                </v-card-title>
                <v-card-text>
                    <v-skeleton-loader type="image" height="200px" width="200px" v-if="loadQR">
                    </v-skeleton-loader>
                    <div id="div-qr" v-html="qrCode" v-else>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="downloadSVG" :disabled="loadQR">
                        Descargar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="qrDialog = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table :headers="headers" :items="users" :items-per-page="-1" :search="searchUser"
                :loading="loadingData" item-key="id" sort-by="id" disable-sort
                no-data-text="No hay datos para mostrar..." loading-text="Cargando los datos, por favor espere."
                class="elevation-1" :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Gesti&oacute;n de Usuarios</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field id="findtext-user" label="Buscar.." name="finduser" outlined dense single-line
                                clearable background-color="white" hide-details v-model="searchUser"
                                class="white--text mt-0 pt-0 mr-2" prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" max-width="600px"
                            persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown" color="primary darken-1" dark
                                    :loading="loadingData" depressed elevation="0" class="mb-2" v-bind="attrs" v-on="on"
                                    @click="newItem">
                                    Adicionar
                                </v-btn>
                                <v-btn @click="getUsers" :loading="loadingData" elevation="0"
                                    :small="$vuetify.breakpoint.smAndDown" class="mb-2 mr-2">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row class="mt-0" dense>
                                                <v-col cols="8">
                                                    <v-text-field v-model="editedItem.name" :disabled="loadingSave"
                                                        label="Nombre">
                                                    </v-text-field>
                                                    <v-text-field v-model="editedItem.last_name" :disabled="loadingSave"
                                                        label="Apellidos">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col class="pl-2 ml-2">
                                                    <v-row justify="center">
                                                        <v-img v-if="imagePreview != null"
                                                            :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                                            max-height="100" max-width="100" aspect-ratio="1"
                                                            :disabled="loadingSave"
                                                            class="white--text align-center justify-center elevation-2">
                                                        </v-img>
                                                    </v-row>
                                                    <v-row>
                                                        <v-file-input v-model="imageFile"
                                                            accept="image/png, image/jpeg, image/bmp" show-size
                                                            clearable :disabled="loadingSave"
                                                            label="Cargar/Cambiar imágen"
                                                            placeholder="Archivo de imágen" prepend-icon="mdi-camera"
                                                            @change="changePreviewImage()">
                                                        </v-file-input>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.email" :rules="emailRules"
                                                        label="E-mail" :disabled="loadingSave" required
                                                        dense></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="isAddItem">
                                                    <v-text-field dense v-model="editedItem.password"
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :rules="rules" :disabled="loadingSave"
                                                        :type="showPassword ? 'text' : 'password'" label="Contraseña"
                                                        required @click:append="showPassword=!showPassword">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select v-model="editedItem.roles" :items="roles"
                                                        :disabled="loadingSave" dense label="Rol de usuario"></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-checkbox v-model="editedItem.active" label="Usuario Activo"
                                                        :disabled="loadingSave" dense></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="close" :disabled="loadingSave">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" :loading="loadingSave"
                                            color="primary darken-2 caption" @click="save">
                                            <v-icon>mdi-content-save</v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Est&aacute; seguro que desea eliminar este Usuario?
                                </v-card-title>
                                <v-card-actions class="caption">
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="primary darken-2" :loading="loadingSave" depressed
                                        @click="deleteItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogPassword" max-width="350px" persistent>
                            <v-card>
                                <v-card-title class="text-h6 primary">
                                    <span class="headline">Cambiar Contrase&ntilde;a</span>
                                </v-card-title>
                                <v-container class="mt-2">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field dense v-model="newPassword"
                                                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="rules" :disabled="loadingSave"
                                                :type="showNewPassword ? 'text' : 'password'" label="Nueva contraseña"
                                                required @click:append="showNewPassword=!showNewPassword">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field dense v-model="confirmPassword" :disabled="loadingSave"
                                                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules, passwordConfirmationRule]"
                                                :type="showConfirmPassword ? 'text' : 'password'"
                                                label="Repita la contraseña" required
                                                @click:append="showConfirmPassword=!showConfirmPassword">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions class="mt-0 pt-0">
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="dialogPassword = false">Cancelar</v-btn>
                                    <v-btn color="primary darken-2 caption" :loading="loadingSave" depressed
                                        @click="changePassword()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Aceptar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogError" persistent max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error Eliminando Usuario</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-10">{{ messageError }}</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="primary" @click.stop="dialogError = false">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
                </template>
                <template v-slot:item.roles="{ item }">
                    {{ capitalizeFirstLetter(item.roles) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadQRAction(item)">
                                mdi-qrcode
                            </v-icon>
                        </template>
                        <span>QR 2FA</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="openDialogPassword(item)">
                                mdi-key-change
                            </v-icon>
                        </template>
                        <span>Cambiar contraseña</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay Usuarios en el listado.</span>
                </template>
            </v-data-table>
            <v-snackbar v-model="snackBar" :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" icon @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import userService from '@/providers/UsersService';

    export default {
        name: "AdminUsersComponent",
        data: () => ({
            valid: true,
            snackBar: false,
            snackText: '',
            newPassword: '',
            confirmPassword: '',
            dialogError: false,
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            loadingData: false,
            loadingSave: false,
            searchUser: '',
            messageError: '',
            dialog: false,
            dialogDelete: false,
            dialogPassword: false,
            imageFile: null,
            imagePreview: null,
            selectedImageIndex: -1,
            qrDialog: false,
            qrCode: "",
            loadQR: false,
            userQR: {},
            rules: [
                value => !!value || 'La contraseña es obligatoria.',
                value => (value || '').length >= 10 || 'La contraseña debe tener al menos de 10 caracteres.'
            ],
            passwordRules: {
                required: v => !!v || "La contraseña es obligatoria",
                min: v => (v && v.length >= 10) || "La contraseña debe tener al menos de 10 caracteres",
            },
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value =>
                    !value ||
                    value.size < 2000000 ||
                    "El tamaño de la imagen no debe de sobrepasar los 2 MB"
            ],
            users: [],
            roles: ['ADMINISTRADOR', 'INVERSIONISTA'],
            headers: [
                {text: 'Id', value: 'id', align: ' d-none', width: 0}, // para ocultar la columna Id.
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text: 'Apellidos', value: 'last_name'},
                {text: 'E-mail', value: 'email'},
                {text: 'Rol', value: 'roles'},
                {text: 'Activo', value: 'active', align: 'center', width: 100},
                {text: 'Acciones', value: 'actions', width: 110, sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                last_name: '',
                email: '',
                password: null,
                roles: 'INVERSIONISTA',
                active: true,
                pictures: []
            },
            defaultItem: {
                id: 0,
                name: '',
                last_name: '',
                email: '',
                password: null,
                roles: 'INVERSIONISTA',
                active: true,
                pictures: []
            },
            isAddItem: false
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
            },
            passwordConfirmationRule() {
                return (this.newPassword === this.confirmPassword) || 'Las contraseñas deben de coincidir.';
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        methods: {
            downloadSVG() {
                const div = document.getElementById("div-qr");
                const svgContent = div.childNodes[2];

                const uriData = `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(svgContent))}`
                const img = new Image();
                img.src = uriData;

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    [canvas.width, canvas.height] = [200, 200];
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, 200, 200);
                    const a = document.createElement("a");
                    const quality = 1.0;
                    a.href = canvas.toDataURL("image/png", quality)
                    a.download = `${this.userQR.name} ${this.userQR.last_name} 2fa.png`;
                    a.append(canvas)
                    a.click()
                    a.remove()
                }
            },
            loadQRAction(item){
                this.userQR = item;
                this.qrDialog = true;
                this.loadQR = true;
                userService.getQRCodeURLById(item.id).then(resp=>{
                    if(resp.success){
                        this.qrCode = resp.value;
                    }
                }).catch(err=>console.log(err)).finally(()=>{
                    this.loadQR = false;
                })
            },
            capitalizeFirstLetter(str) {
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            },
            getUsers() {
                // carga los datos desde el proveedor de datos.
                this.loadingData = true;
                userService.getAllRecords().then(record => {
                    // // console.log('record: ', record.value);
                    this.users = record.value;
                    // this.users = record.data;
                    // console.log("Usuarios: ", this.users);
                    this.isAddItem = false;
                }).catch(err=>console.log(err)).finally(()=>{
                  this.loadingData = false;
                });
            },
            newItem() {
                this.isAddItem = true;
                this.valid = false;
                this.imagePreview = null;
                this.imageFile = null;
                this.selectedImageIndex = -1;
            },
            editItem(item) {
                this.isAddItem = false;
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.imagePreview = null;
                this.selectedImageIndex = -1;
                if (this.editedItem.pictures) {
                    if (this.editedItem.pictures.length > 0) {
                        this.imagePreview = this.editedItem.pictures[0].url;
                        this.selectedImageIndex = this.editedItem.pictures[0].id;
                    }
                }
                this.imageFile = null;
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
                this.isAddItem = false;
            },
            deleteItemConfirm() {
                this.closeDelete();
                this.archiveItemId = this.editedItem;
                // console.log("this.editedItem.id: ", this.editedItem.id);
                this.loadingSave = true;
                userService.deleteRecord(this.editedItem.id).then(
                    response => {
                        // console.log("response: ", response);
                        if (response.success) {
                            this.users.splice(this.editedIndex, 1);
                            this.snackText = 'Usuario eliminado con éxito.';
                            this.snackBar = true;
                            this.getUsers();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error Impidió eliminar este Usuario. " +
                        "Esto ocurre debido a que el Usuario está asignado " +
                        "a uno o varios proyectos dentro del sistema.";
                    // console.log("this.archiveItemId: ", this.archiveItemId);
                    // this.archiveItem(this.archiveItemId);
                    this.dialogError = true;
                }).finally(() => {
                    // console.log("result: ", result);
                    // this.closeDelete();
                    // this.snackBar = true;
                    this.loadingSave = false;
                });

                /*userService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            this.users.splice(this.editedIndex, 1);
                            this.snackText = 'Usuario eliminado con éxito.';
                        } else {
                            this.snackText = 'Un error impidió eliminar este Usuario';
                        }
                    }
                );
                this.closeDelete();
                this.snackBar = true;*/
            },
            openDialogPassword(item) {
                this.newPassword = '';
                this.confirmPassword = '';
                this.showNewPassword = false;
                this.showConfirmPassword = false;
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogPassword = true;
            },
            changePassword() {
                this.dialogPassword = false;
                this.loadingSave = true;
                userService.changePassword(this.editedItem.id, this.newPassword).then(
                    response => {
                        if (response.success) {
                            this.snackText = 'Contraseña cambiada con éxito.';
                        } else {
                            this.snackText = 'Un error impidió cambiar la contraseña.';
                        }
                        this.snackBar = true;
                        this.getUsers();
                    }
                ).catch(err=>console.log(err)).finally(()=>{
                    this.loadingSave = false;
                });
            },
            close() {
                this.isAddItem = false;
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.imagePreview = null;
                    this.selectedImageIndex = -1;
                })
            },
            closeDelete() {
                this.isAddItem = false;
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                })
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    this.loadingSave = true;
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.users[this.editedIndex], this.editedItem);
                        userService.updateRecord(this.editedItem, this.selectedImageIndex, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getUsers();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>{
                          this.loadingSave = false;
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        this.users.push(this.editedItem);
                        userService.addRecord(this.editedItem, -1, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getUsers();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>{
                          this.loadingSave = false;
                        });
                    }
                    this.isAddItem = false;
                    this.close();
                    this.snackText = 'Usuario guardado con éxito.';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    if (this.editedItem.pictures.length === 0) {
                        let imagePicture = {
                            id: 0,
                            module_id: 1,
                            url: this.imageFile.name
                        };
                        this.editedItem.pictures.push(imagePicture);
                        // console.log('Picture actualizado: ', this.editedItem);
                    }
                    this.snackText = 'Imágen de perfil cargada con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.isAddItem = false;
            this.users = [];
            this.getUsers();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>